import React, {Component} from 'react';

// Soft UI Dashboard React themes
import theme from "assets/theme";

import { ThemeProvider } from "@mui/material/styles";
import { BaseHeader, BaseContainer, BaseFooter } from 'layouts/Base';
import MainForm from 'layouts/MainForm'
import Loader from 'layouts/utils/Loader';
import { OpenkotProvider, MetaHelmet } from 'layouts/utils/Context';
import { Route, Routes } from 'react-router-dom';
import { LicensesList, RoutedLicensePage } from 'layouts/LicensesList';
import { BlogPage } from 'layouts/Blog';
import { YMInitializer } from 'react-yandex-metrika';

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
          <OpenkotProvider>
            <MetaHelmet />
            <YMInitializer accounts={[86307427]} />
            <section style={{minHeight: "100vh", marginBottom: 8}}>
              <BaseHeader />
              <BaseContainer>
                <Routes>
                  <Route exact path="*" element={<MainForm />} />
                  <Route path="/licenses" element={<LicensesList />} />
                  <Route path="/licenses/:license" element={<RoutedLicensePage />} />
                  <Route path="/blog/:slug" element={<BlogPage />} />
                </Routes>
              </BaseContainer>
              <BaseFooter />
            </section>
            <Loader />
          </OpenkotProvider>
      </ThemeProvider>
    );
  }
}
export default App;
