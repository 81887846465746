import { Box, Card, CardActionArea, CardContent, CardHeader, Grid, List, ListItem, Typography, formControlLabelClasses } from "@mui/material";
import { Component } from "react";
import { OpenkotContext, withRouter } from "./utils/Context";
import { http } from "./utils/Connector";
import { Link } from "react-router-dom";
import breakpoints from "assets/theme/base/breakpoints";

export class Page extends Component {
    constructor (props) {
        super(props);
    }
    state = {
        page: [],
    }

    static contextType = OpenkotContext;

    setPage = (page) => {
        this.setState({page: page});
        this.context.setPage({seoDescription: page.seo_description, seoKeywords: page.seo_keywords, title: `OpenKot - ${page.title} | Справочник лицензий`});
    }

    componentDidMount() {
        this.context.upLoader();
        http.get(`/api/posts/${this.props.router.params.slug}/`).then((res) => {this.setPage(res.data); this.context.downLoader();})
    }

    render () {
        return(
            this.state.page &&
                <Grid item xs={12} mx={document.body.clientWidth < breakpoints.values.sm ? "0.5rem" : "4rem"}>
                    <Typography
                        component='h3'
                        variant='h3'
                        >{this.state.page.title}
                    </Typography>
                    <Typography
                        component='p'
                        variant='body2'
                        dangerouslySetInnerHTML={{ __html: this.state.page.content }} >
                    </Typography>
                </Grid>
            
        )
    }
};

export const BlogPage = withRouter(Page);

export class BlogTable extends Component {
    constructor (props) {
        super(props);
    }

    state = {
        pages: [],
    };

    static contextType = OpenkotContext;

    setPages = (pages) => {
        this.setState({pages: pages});
    }

    componentDidMount() {
        this.context.upLoader();
        http.get(`/api/blog-posts/`).then((res) => {this.setPages(res.data); this.context.downLoader();})
    }
    
    render () {
        return( 
            this.state.pages.length > 0 && 
            <Grid item xs={11} lg={7} px={"0.5rem"} pt={"4rem"}>
                <Typography color="#252f40" variant="h6" component="h6" 
                sx={{
                    fontSize: '1.25rem',
                    lineHeight: '1.375',
                    letterSpacing: '-0.05rem'
                }}>
                    Блог Open Кота
                </Typography>
                <Grid container spacing={3}>
                    { this.state.pages.map(page => (
                        <Grid key={page.slug} item xs={12} md={6} py={"1rem"}>
                            <Card sx={{boxShadow: "0 5px 7px 0 rgb(0 0 0 / 15%)", height: "100%"}}>
                                    <CardActionArea component={Link} to={`/blog/${ page.slug }`}>
                                        <CardHeader
                                            titleTypographyProps = {{
                                                color: "white!important",
                                            }}
                                            subheaderTypographyProps = {{
                                                color: "white!important",
                                                fontSize: "0.8rem"
                                            }}
                                            sx={{
                                                backgroundColor: "#60A2AB",
                                                height: "8rem",
                                                alignItems: "flex-end",
                                            }}
                                            title={ page.title }
                                            subheader={ page.change_dttm.substr(0,10) } />
                                        <CardContent>
                                            <Typography variant="caption">
                                                { page.seo_description }
                                            </Typography>
                                        </CardContent>        
                                    </CardActionArea>
                            </Card>
                        </Grid>
                    )) }
                </Grid>
            </Grid>  
        )
    }
};

export const Blog = withRouter(BlogTable);
