import { List, ListItem, ListItemText, Box, ListItemButton, Grid } from '@mui/material'
import { Component } from 'react'
import { http } from './utils/Connector'
import { OpenkotContext, withRouter } from './utils/Context'
import { Link } from 'react-router-dom'
import { License } from './License'

export class LicensesList extends Component {
    state = {
        array: [],
    }

    static contextType = OpenkotContext;

    componentDidMount() {
        this.context.upLoader();
        http.get('/api/licenses/').then((res) => {this.setState({array: res.data}); this.context.downLoader();})
    };

    render () {
        return(
            <Grid item xs={10} mx={"auto"}>
                <List>
                    {this.state.array.map((lic, i) => {
                        return (
                        <ListItem key={i} disablePadding>
                            <Link style={{width: "100%"}} to={`/licenses/${lic.license_id}`}>
                                <ListItemButton width={"100%"}>
                                    <Box>
                                        
                                        <ListItemText secondary={`${lic.name} (${lic.license_id.toUpperCase()})`}/>   
                                        {/* <Typography 
                                            variant='spoiler'
                                            component='p'>
                                                {lib.lic_name}
                                        </Typography> */}
                                    </Box>
                                </ListItemButton>
                            </Link>
                        </ListItem>)
                    })}
                </List>
            </Grid>
        )
    }
}


class LicensePage extends Component {
    state = {
        license: null,
    }

    static contextType = OpenkotContext;

    setLicense = (license) => {
        this.setState({license: license});
        this.context.setPage({seoDescription: license.seo_description, seoKeywords: license.seo_keywords[0], title: `OpenKot - ${license.license_id} | Справочник лицензий`});
    }

    componentDidMount() {
        this.context.upLoader();
        http.get(`/api/licenses/${this.props.router.params.license}/`).then((res) => {this.setLicense(res.data); this.context.downLoader();})
    };

    render () {
        return(
            <Grid item xs={12} mx={"1rem"}>
                {this.state.license && <License key={this.state.license.license_id} {...this.state.license}/>}
            </Grid>
        )
    }
}

const RoutedLicensePage = withRouter(LicensePage);

export { RoutedLicensePage }