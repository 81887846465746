import React, {Component} from 'react';
import { Grid, Button, ListItem, Tooltip, List, CardContent, Link, Paper, MenuItem, MenuList, Typography, Box } from '@mui/material';
import 'layouts/static/Base.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCat, faQuestionCircle, faPen, faTimes, faStar, faClone, faFlag } from '@fortawesome/free-solid-svg-icons';
import { faOsi } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Flag } from '@mui/icons-material';

library.add(
    faCat, faOsi, faQuestionCircle, faPen, faTimes, faStar, faClone, faFlag
);

const none_text = "Лапки OpenКота пока не дотянулись до этой лицензии"
+"<br>            ☆ *　. 　☆"
+"<br>　　. ∧＿∧　∩　* ☆"
+"<br>* ☆ ( ・∀・)/ ."
+"<br>　. ⊂　　 ノ* ☆"
+"<br>☆ * (つ ノ .☆"
+"<br>　　 (ノ"

const generateKey = (pre) => {
    return `${ pre }_${ new Date().getTime() }`;
}

let attrMap = {
    allow: {
        title: "Разрешает",
        icon: "star",
        color: "#82d616"
    },
    deny: {
        title: "Ограничивает",
        icon: "times",
        color: "#ea0606"
    },
    require: {
        title: "Требует",
        icon: "pen",
        color: "#17c1e8"
    }
}

class LicenseAttr extends Component {
    constructor(props) {
        super(props);
        this.attrMap = {
            allow: {
                title: "Разрешает",
                icon: "star",
                color: "#82d616"
            },
            deny: {
                title: "Ограничивает",
                icon: "times",
                color: "#ea0606"
            },
            require: {
                title: "Требует",
                icon: "pen",
                color: "#17c1e8"
            }
        }[props.type]
    }

    render() {
        return(
            <Grid item sm={12/this.props.len}>
                <List className="LicAttributeList">
                    <Typography 
                        variant="body2" 
                        mb={"0.5rem!important"}
                        component="p">
                        {this.attrMap.title}
                    </Typography>
                    {
                        this.props.attrs.map((x, i) => {
                            return (
                                <ListItem key={ generateKey(i) }>
                                    <Typography 
                                        variant="caption" 
                                        component="p"
                                        mb={"1rem!important"}
                                        width={"85%"}>
                                            <FontAwesomeIcon icon={["fas", this.attrMap.icon]} style={
                                                {
                                                    color: this.attrMap.color, 
                                                    paddingRight: "0.3rem",
                                                }
                                                } />
                                            { x.description ? 
                                                <Tooltip title={x.description} placement="top">
                                                    <span><FontAwesomeIcon icon={['fas', x.red_flag ? 'fa-flag' : 'fa-question-circle' ]} style={{color: x.red_flag ? "red" : "grey", paddingRight: "0.3rem"}} />{x.condition}</span>
                                                </Tooltip>  : x.condition
                                            }
                                    </Typography>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Grid>
        )
    }
}


class License extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        showInstructions: null,
        instructionsTitles: {
        template: `Текст лицензии ${this.props.name}`,
        ru_template: `Перевод OpenКот лицензии ${this.props.name}`,
        how_to_license: `Как использовать ПО с лицензией  ${this.props.name}`,
        how_to_use: `Как применить лицензию  ${this.props.name}`,}
    };

    countAttrsWidth = () => {
        let len = 0;
        if (this.props.allow.length > 0) {
            len += 1
        };
        if (this.props.deny.length > 0) {
            len += 1
        };
        if (this.props.require.length > 0) {
            len += 1
        };
        return (len);
    }

    handleMenu = (item) => {
        this.setState({showInstructions: item.target.id})
    }

    render() {
        let len = this.countAttrsWidth();
        return (
           <ListItem className="MainListItem">
                <Grid container width={"100%!important"}>
                    <Grid className="LicenseSection" item lg={8} px={"0.5rem"} >
                        <Grid container className='Row' mb={"1rem!important"} alignContent={"flex-start"}>
                            { (this.props.ratio && this.props.area) && 
                            <Typography 
                                variant="h6" 
                                component="h6"
                                width={"100%"}
                                mb={"0.5rem!important"}
                                ml={"1rem"}>
                                    OpenKot на {this.props.ratio}% уверен, что это та лицензия, которую вы ищите 
                            </Typography>}
                            <Typography 
                                variant="h6" 
                                component="h6"
                                width={"100%"}
                                mb={"0.5rem!important"}
                                ml={"1rem"}>
                                    <Link href={`/licenses/${this.props.id}`} target="_blank">{`${this.props.name} (${this.props.id.toUpperCase()})`}</Link>
                                    { this.props.osi_approved && <Tooltip title="Лицензия одобрена OSI" placement="top">
                                        <FontAwesomeIcon icon={['fab', 'osi']} style={{marginLeft: "5px"}} />
                                    </Tooltip> }
                                    { this.props.ru_template && <Tooltip title="Лицензия переведена OpenKot" placement="top">
                                        <FontAwesomeIcon icon={['fas', 'cat']} style={{marginLeft: "5px"}} />
                                    </Tooltip> }
                            </Typography>
                            <Typography 
                                variant="caption" 
                                component="p"
                                mb={"1rem!important"}
                                pl={"1rem"}
                                dangerouslySetInnerHTML={{ __html: this.props.notes }} >
                            </Typography>
                            {len > 0 ?
                            <Grid container pl={"1rem"}>
                                { this.props.require.length > 0 ? <LicenseAttr key={ generateKey("req") } type="require" attrs={this.props.require} len={len} /> : null }
                                { this.props.allow.length > 0 ? <LicenseAttr key={ generateKey("alw") } type="allow" attrs={this.props.allow} len={len} /> : null }
                                { this.props.deny.length > 0 ? <LicenseAttr key={ generateKey("den") } type="deny" attrs={this.props.deny} len={len} /> : null }
                            </Grid> : null}
                        </Grid>
                    </Grid>
                    <Grid className="LicenseSection Card" item xs={12} lg={4} height={"250px!important"} bgcolor={"#fff"} width={"100%"}>
                        <Paper sx={{ 
                            width: "100%",
                            margin: "1rem", 
                            background: "#f8f9fa" }}>
                            <MenuList sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    flexWrap: "wrap",
                                    justifyContent: "space-evenly"
                                }}>
                                <MenuItem id={"template"} onClick={this.handleMenu} sx={{justifyContent: "center !important"}}>Оригинальный текст</MenuItem>
                                <MenuItem id={"ru_template"} onClick={this.handleMenu} sx={{justifyContent: "center !important"}}>Перевод OpenKot</MenuItem>
                                <MenuItem id={"how_to_license"} onClick={this.handleMenu} sx={{justifyContent: "center !important"}}>Как применить лицензию?</MenuItem>
                                <MenuItem id={"how_to_use"} onClick={this.handleMenu} sx={{justifyContent: "center !important"}}>Как использовать ПО?</MenuItem>
                            </MenuList>
                        </Paper>
                    </Grid>
                    {this.state.showInstructions && 
                    <Grid container px={"0.5rem"} mt={"1rem"} xs={12} className="MainListItem" item width={"100%!important"} bgcolor={"#fff"}>
                        <CardContent sx={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                            <Typography
                                variant='h6'
                                component='h6'
                                sx={{color: "#000000"}}
                                >
                                {this.state.instructionsTitles[this.state.showInstructions]}
                            </Typography>
                            <div>
                                <Button variant="text" width={"20%"} onClick={() => {navigator.clipboard.writeText(this.props[this.state.showInstructions])}}>
                                    <FontAwesomeIcon icon={['fas', 'clone']} />
                                </Button>
                                <Button variant="text" width={"20%"} onClick={this.handleMenu}>
                                    <FontAwesomeIcon icon={['fas', 'times']} />
                                </Button>
                            </div>
                        </CardContent>
                        <Paper elevation={0} style={{maxHeight: "80vh", overflow: 'auto'}}>
                            <Typography
                                variant='body2'
                                component='p'
                                fontSize={"1rem"}
                                px={"1.5rem"}
                                dangerouslySetInnerHTML={{__html: this.props[this.state.showInstructions] ? this.props[this.state.showInstructions] : none_text}}
                                >
                            </Typography>
                        </Paper>
                    </Grid>
                    }
                </Grid>
           </ListItem>
        );
    }
};

export { License }