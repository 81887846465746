import React, { Component } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { seo } from "layouts/static/seo";
import { Helmet } from "react-helmet";

export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
}

const OpenkotContext = React.createContext();

export class BaseOpenkotProvider extends Component {
    setLoader = loader => {
        this.setState({ Loader: loader });
    };

    toggleLoader = () => {
        this.setLoader(!this.state.Loader)
    };

    setPage = (page) => {
        this.setState({pageOptions: page});
    }

    state = {
        Loader: false,
        toggleLoader: this.toggleLoader,
        upLoader: () => this.setLoader(true),
        downLoader: () => this.setLoader(false),
        setPage: (page) => this.setPage(page),
        pageOptions: {
            seoDescription: seo[this.props.router.location.pathname] ? seo[this.props.router.location.pathname].description : "",
            seoKeywords: seo[this.props.router.location.pathname] ? seo[this.props.router.location.pathname].keywords: "",
            title: seo[this.props.router.location.pathname] ? seo[this.props.router.location.pathname].title: "OpenKot | Справочинк лицензий"
        }
    };

    render() {
        return (
        <OpenkotContext.Provider value={this.state}>
            {this.props.children}
        </OpenkotContext.Provider>
        );
    }
};

export const OpenkotProvider = withRouter(BaseOpenkotProvider);

class MetaHelmet extends Component {
    render() {
        return(
            <Helmet
                title={this.context.pageOptions.title}
                meta={[
                    {name: "title", content: this.context.pageOptions.title},
                    {name: "description", content: this.context.pageOptions.seoDescription},
                    {name: "keywords", content: this.context.pageOptions.seoKeywords},
                    {name: "viewport", content: "width=device-width, initial-scale=1, maximum-scale=1"},
                    {name: "apple-mobile-web-app-capable", content: "yes"},
                    {name: "apple-mobile-web-app-status-bar-style", content: "black-translucent"},
                ]} />
        )
    }
}

MetaHelmet.contextType = OpenkotContext;

export default OpenkotProvider;
export { OpenkotContext, MetaHelmet }