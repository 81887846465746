import React, {Component} from 'react';
import { Box, Typography, FormControl, FormLabel, FormGroup, TextField, InputLabel, Input, InputAdornment, Button, ListItem, Card, Tooltip, List, CardHeader, CardContent, ListItemText, ListItemButton, ListItemIcon } from '@mui/material';
import SoftTypography from 'components/SoftTypography';
import Item from '@mui/material/Grid'
import Grid from '@mui/material/Grid'
import 'layouts/static/Base.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCat, faQuestionCircle, faPen, faTimes, faStar, faClone } from '@fortawesome/free-solid-svg-icons';
import { faOsi } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import InboxIcon from '@mui/icons-material/Inbox';
import { GitHub, Error, OpenInNew, Close, Star } from '@mui/icons-material';

library.add(
    faCat, faOsi, faQuestionCircle, faPen, faTimes, faStar, faClone
);

export class LibraryList extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        array: this.props.array,
        chosen: null
    }

    error = (
        <List>
            <ListItemIcon>
                <Error />
            </ListItemIcon>
            <ListItemText primary="Inbox" />
        </List>
    );

    render() {
        return (
            <List>
                {!Array.isArray(this.props.array) ? (
                    <ListItem id="error_lib" disablePadding sx={{background: "#fc9797"}}>
                        <ListItemButton>
                            <ListItemIcon>
                                <Error />
                            </ListItemIcon>
                            <ListItemText secondary="Не удалось найти библиотеку" />
                        </ListItemButton>
                    </ListItem>
                ) : this.state.array.map((lib, i) => {
                    return (<ListItem key={i} disablePadding>
                        <ListItemButton onClick={() => {this.setState({chosen: lib.id, array: [lib]}); this.props.choice(lib)}}>
                            <ListItemIcon>
                                <GitHub />
                            </ListItemIcon>
                            <Grid container>
                                <Grid item xs={8}>
                                    <ListItemText secondary={lib.name} />   
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography 
                                        variant='caption'
                                        component='p'>
                                            <Star />
                                            {lib.stargazers_count}
                                    </Typography>  
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography 
                                        variant='caption'
                                        component='p'>
                                            {lib.description}
                                    </Typography>    
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography 
                                        variant='spoiler'
                                        component='p'>
                                            {lib.lic_name}
                                    </Typography>  
                                </Grid>
                            </Grid>
                        </ListItemButton>
                        <Button variant="text" width={"20%"} onClick={() => window.open(`https://github.com/${lib.name}`, '_blank')}>
                            <OpenInNew />
                        </Button>
                        {lib.id == this.state.chosen && 
                        <Button variant="text" width={"20%"} onClick={this.props.unchoice}>
                            <Close/>
                        </Button>}
                    </ListItem>)
                })}
            </List>
        );
    }
}