import React, {Component} from 'react';
import { Box, Typography, FormControl, InputLabel, Input, InputAdornment, Button, List, Grid } from '@mui/material';
import 'layouts/static/Base.css';
import { Search, GitHub } from '@mui/icons-material';
import { License } from './License';
import { LibraryList } from './Library';
import { OpenkotContext } from './utils/Context';
import { http } from './utils/Connector';
import { BlogTable } from './Blog';
import ym from 'react-yandex-metrika';

const MIN_LENGTH = 3

class MainForm extends Component {
    constructor (props) {
        super(props);

        this.refName = React.createRef(null);
        this.refLib = React.createRef(null);
        this.refText = React.createRef(null);
    }

    state = {
        text: "",
        lid: "",
        lib: "",
        chosed: false,
        licenses: [],
        libraries: [],
        position: "",
        loader: false,
    }

    static contextType = OpenkotContext;

    handleLicenseChange = (lic) => {
        this.setState({licenses: lic});
    }

    handleTextChange = (event) => {
        this.setState({text: event.target.value})
        this.setState({licenses: []})
    }

    handleLidChange = (event) => {
        this.setState({lid: event.target.value})
        this.setState({licenses: []})
        if (event.target.value.length >= MIN_LENGTH) {
            ym('reachGoal','findByName');
            this.setState({position: "name"});
            this.context.upLoader();
            http.get(`/api/licenses/find-by-name/?name=${event.target.value.toLowerCase()}`).then((res) => {this.handleLicenseChange(res.data); this.context.downLoader();}).catch((err) => {console.log(err); this.context.downLoader();});
        }
    }

    handleLibChange = (event) => {
        this.setState({lib: event.target.value})
        this.setState({licenses: []})
        if (event.target.value.length >= MIN_LENGTH + 1) {
            this.setState({position: "lib"});
            this.context.upLoader();
            http.get(`api/libraries/?lib=${event.target.value.toLowerCase()}`).then((res) => {this.setState({libraries: res.data}); this.context.downLoader(); ym('reachGoal','findLib');});
        } else {
            this.setState({libraries: []});
        }
    }

    handleLibChoice = (event) => {
        this.setState({chosed: true});
        this.context.upLoader();
        http.get(`api/libraries/?id=${event.id}&source=${event.src}&license=${event.lic_name}`).then((res) => {this.handleLicenseChange(res.data); this.context.downLoader(); ym('reachGoal','findByLib');}).catch((err) => {console.log(err); this.context.downLoader();});
    }

    handleLibUnChoice = () => {
        this.setState({chosed: false, lib: "", licenses: [], libraries: []})
    }

    handleTextFind = () => {
        if (this.state.licenses.length > 0 && this.state.position == "text") {
            this.setState({text: ""})
            this.setState({licenses: []})
        } else {
            this.setState({position: "text"});
            this.context.upLoader();
            http.get(`api/licenses/find-by-text/?text=${this.state.text}`).then((res) => {this.handleLicenseChange(res.data); this.context.downLoader();}).catch((err) => {console.log(err); this.context.downLoader();});
        }
    }

    render () {
        return (
            <Box className="Row" justifyContent={"center"}>
                <Grid item xs={11} lg={7} px={"0.5rem"}>
                    <FormControl variant="standard" sx={{minWidth: "100%"}}>
                        <InputLabel htmlFor="input-license-id">
                            <Typography color="#252f40" variant="h5" component="h6">Поиск по названию лицензии</Typography>
                        </InputLabel>
                        <Input
                            id="input-license-id"
                            value={this.state.lid}
                            onChange={this.handleLidChange}
                            placeholder="Введите название (например, Apache 2.0)"
                            fullWidth
                            sx={{marginTop: "1.5rem !important", '& .MuiInput-input': {minWidth: "100%!important"},}}
                            startAdornment={
                                <InputAdornment position="start">
                                <Search />
                                </InputAdornment>
                            }
                        />
                        <Typography 
                            sx={{
                                marginTop: "0.25rem",
                                marginBottom: "2rem",
                                color: "#67748e"
                            }}
                            variant="caption" 
                            component="p">Начните вводить название лицензии (полное или краткое)</Typography>
                    </FormControl>
                </Grid>
                <List width={"100%"} ref={this.refName} id="list-findbyname" className='MainListGroup'>
                    {this.state.position == "name" && this.state.licenses.length > 0 && this.state.licenses.map(lic => (<License key={lic.id} {...lic}/>))}
                </List>
                <Grid item xs={11} lg={7} px={"0.5rem"} pt>
                    <FormControl variant="standard" sx={{width: "100%"}}>
                        <InputLabel htmlFor="input-license-lib">
                            <Typography 
                                sx={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                                color="#252f40" 
                                variant="h5" 
                                component="h6">Поиск лицензии по библиотеке <GitHub sx={{marginLeft: "0.5rem"}} /></Typography>
                        </InputLabel>
                        <Input
                            id="input-license-lib"
                            value={this.state.lib}
                            onChange={this.handleLibChange}
                            placeholder="Введите название (например, Flask)"
                            disabled={this.state.chosed}
                            sx={{
                                marginTop: "1.5rem !important", 
                                '& .MuiInput-input': {minWidth: "100%!important"},
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                <Search />
                                </InputAdornment>
                            }
                        />
                        {this.state.position == "lib" && this.state.libraries.length > 0 && <LibraryList array={this.state.libraries} choice={this.handleLibChoice} unchoice={this.handleLibUnChoice}/>}
                        <Typography 
                            sx={{
                                marginTop: "0.25rem",
                                marginBottom: "2rem",
                                color: "#67748e"
                            }}
                            variant="caption" 
                            component="p">Начните вводить название библиотеки</Typography>
                    </FormControl>
                </Grid>  
                <List width={"100%"} ref={this.refName} id="list-findbylib" className='MainListGroup'>
                    {this.state.position == "lib" && this.state.licenses.length > 0 && this.state.licenses.map(lic => (<License key={lic.id} {...lic}/>))}
                </List>
                <Grid item xs={11} lg={7} px={"0.5rem"} pt>
                    <FormControl variant="standard" sx={{width: "100%"}}>
                        <InputLabel htmlFor="input-license-text">
                            <Typography 
                                sx={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                                color="#252f40" 
                                variant="h5" 
                                component="h6">Поиск лицензии по тексту</Typography>
                        </InputLabel>
                        <Input
                            id="input-license-text"
                            value={this.state.text}
                            onChange={this.handleTextChange}
                            multiline
                            rows={8}
                            placeholder="Введите название"
                            sx={{marginTop: "1.5rem !important", '& .MuiInput-input': {minWidth: "100%!important"},}}
                            startAdornment={
                                <InputAdornment position="start">
                                </InputAdornment>
                            }
                        />
                        <Typography 
                            sx={{
                                marginTop: "0.25rem",
                                marginBottom: "0",
                                color: "#67748e"
                            }}
                            variant="caption" 
                            component="p">Поиск доступен при длине текса больше 100 символов</Typography>
                        <Button 
                            variant="contained"
                            disabled={this.state.text.length <= 100}
                            onClick={this.handleTextFind}
                            sx={{
                                backgroundImage: "linear-gradient(310deg, #141727 0%, #3A416F 100%)",
                                color: "#fff",
                                textTransform: "uppercase",
                                boxShadow: "0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%)",
                                backgroundSize: "150%",
                                marginBottom: "0.5rem",
                                marginTop: "1.5rem"
                            }}
                        >{(this.state.licenses.length > 0 && this.state.position == "text")? "Очистить":"Найти"}{ this.state.text.length <= 100 ? ` (нужно ещё символов: ${100-this.state.text.length})` : null}</Button>
                    </FormControl>
                    {/* <OpenkotContext.Consumer>
                        {({Loader, toggleLoader}) => (
    
                            <Button 
                            variant="contained"
                            onClick={toggleLoader}
                        >Лоадер {`${Loader}`}</Button>
                        )}
                    </OpenkotContext.Consumer> */}
                </Grid>  
                <List ref={this.refText} id="list-findbytext" className='MainListGroup'>
                {this.state.position == "text" && this.state.licenses.length > 0 && this.state.licenses.map(lic => (<License key={lic.id} {...lic} area={this.state.position}/> ))}
                </List>
                <BlogTable />
            </Box>
        );
    }
}

export default MainForm;