/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import colors from "assets/theme/base/colors";

const { black } = colors;

const link = {
  // defaultProps: {
  //   underline: "none",
  //   color: "inherit",
  // },
  styleOverrides: {
    root: {
      
      color: black.main,
      transition: "background-color 300ms ease, color 300ms ease",

      "&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
        color: black.focus,
      },
    },
  },
};

export default link;
