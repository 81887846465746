import React, {Component} from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import SoftTypography from 'components/SoftTypography';
import Item from '@mui/material/Grid'
import Grid from '@mui/material/Grid'
import 'layouts/static/Base.css';
import logo from 'assets/images/openkot2.png';
import Nav from './Nav';
import { http } from './utils/Connector';
import { Link } from 'react-router-dom';
import { Telegram } from '@mui/icons-material';


class BaseHeader extends Component {
    render() {
        return (
            <Box className="BaseHeader"
                pb={32}
                pt={16}
                alignItems={"start"}
                mx={"auto"}
                minHeight={"20vh"}
            >
                <Grid className="Row" container justifyContent={"center"}>
                    <Grid item sm={4} textAlign={{xs: "center", sm: "right"}}>
                        <Item>
                            <a className="Logo" href="/">
                                <img src={logo} className="Logo" style={{maxHeight: '8rem'}}  alt="OpenKot - Логотип сгенерирован моделью ruDALL-E от Сбера" />
                            </a>
                        </Item>
                    </Grid>
                    <Grid item sm={5} textAlign={{xs: "center", sm: "left"}} mt={{md: 4}}>
                        <Item>
                            <SoftTypography color="white" variant="h1" component="h1">Open Кот</SoftTypography>
                            <SoftTypography color="white" variant="body2" component="h5">Инструкция по применению Open Source</SoftTypography>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

class BaseContainer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Grid container px={{xs: 0, lg: "4rem", xl: "16rem"}} mt={{xs: "-12rem", md: "-14rem", lg: "-12rem"}}>
                <Grid item xs={12} mx={"auto"}>
                    <Box className="Card" zIndex={0}>
                        <Box className="CardBody" pb={"8rem"} pt={"2rem"}>
                            <Nav />
                           {this.props.children}   
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

class BaseFooter extends Component {
    state = {
        pages: []
    }

    componentDidMount() {
        http.get('/api/footer-posts/').then((res) => this.setState({pages: res.data}));
    }

    render() {
        return(
            <Box component="footer" py={4} mt={8}>
                {this.state.pages.length > 0 &&
                    <Grid container mb={2} py={4} sx={{
                        width: "100%",
                        justifyContent: "center",
                        margin: "0",
                        textAlign: "center",
                    }}>
                        {this.state.pages.sort((a,b) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0)).map(page => (
                            <Grid key={"_"+page.slug} item xs={12} ><Link key={page.slug} target="_blank" to={`/blog/${page.slug}`} style={{textDecoration: "none"}}>
                                <Typography
                                    component="p"
                                    variant="body2"
                                    color={"#8392AB"}
                                    >
                                        {page.title}
                                </Typography>
                            </Link>
                        </Grid>
                        ))}
                    </Grid>}
                <Box mx={"auto"} textAlign={"center"} mb={4}>
                    <a href='https://hse.ru/' target='_blank' className="align-middle mx-auto pt-1">
                        <img src="https://www.hse.ru/images/main/main_logo_ru.svg" width="200px" className="align-middle mx-auto pt-1" />
                    </a>
                </Box>
                <Box mx={"auto"} textAlign={"center"} mb={4} sx={{display: 'flex', justifyContent: "center"}}>
                    <a href="https://t.me/openkot_support" target='_blank'>
                        <Avatar>
                            <Telegram sx={{width:"10rem"}}/>
                        </Avatar>
                    </a>
                </Box>
                <Box mx={"auto"} textAlign={"center"} mb={4} sx={{display: 'flex', justifyContent: "center"}}>
                        <Typography variant="caption" component="h6" fontSize={"0.75em"} color="text.secondary">
                            ОПЕНКОТ.РУ (openkot.ru) | Справочник открытых лицензий
                        </Typography>
                </Box>
            </Box>
        )
    }
}

export { BaseHeader, BaseContainer, BaseFooter };