import React, { Component } from "react";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import {SearchRounded, List} from '@mui/icons-material'
import { withRouter } from 'layouts/utils/Context'
import { Link } from "react-router-dom";
 
class Nav extends Component {
    constructor (props) {
        super(props);
        this.refNav = React.createRef(null);
    }

    path = {
        "/": 0,
        "/licenses": 1,
    }

    state = {
        value: this.path[this.props.router.location.pathname] ? this.path[this.props.router.location.pathname] : null,
        // mobile: false,
    }

    setNavValue = (value) => {
        this.setState({value: value});
    }

    // stickyNav = (e) => {
    //     if (document.body.clientWidth < 576) {
    //         this.setState({mobile: true})
    //     }
    // }

    // componentDidMount(){
    //     this.stickyNav()
    // }

    render () {
        return(
            <BottomNavigation
                id="__navbar"
                ref = {this.refNav}
                showLabels
                sx={{ 
                    width: "100%",
                    height: "6rem!important",
                    paddingBottom: "2rem",
                    // position: this.state.mobile ? 'fixed' : null,
                    // bottom: this.state.mobile ? 0 : null,
                    // zIndex: this.state.mobile ? '100' : 0,
                    // width: this.state.mobile ? `${this.refNav.current.parentElement.clientWidth}px!important` : "100%",
                }}
                value={this.state.value}
                onChange={(event, newValue) => {
                    this.setNavValue(newValue);
            }}>
                <BottomNavigationAction component={Link} to="/" height={"4rem"} label="Поиск" icon={<SearchRounded sx={{width:"1.5em", height:"1.5em"}}/>} />
                <BottomNavigationAction component={Link} to="/licenses" label="Список" icon={<List sx={{width:"1.5em", height:"1.5em"}}/>} />
            </BottomNavigation>
        )
    };
}

const NavComponent = withRouter(Nav);

export default NavComponent;