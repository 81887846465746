import { Box } from '@mui/material';
import React, {Component} from 'react';
import { OpenkotContext, OpenkotProvider } from './Context';
import logo from 'assets/images/openkot2.png';

class Loader extends Component {
    render() {
        return (
            <Box 
                id="contextLoaderBlock" 
                sx={{
                    display: this.context.Loader ? "block": "none",
                }} 
                className="LoaderBox">
                <a className="Logo LoaderLogo" href="/">
                    <img src={logo} className="Logo" style={{maxHeight: '8rem'}}  alt="OpenKot - Логотип сгенерирован моделью ruDALL-E от Сбера" />
                </a>
            </Box>
        );
    }
}

Loader.contextType = OpenkotContext;

export default Loader